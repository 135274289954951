/* @import url("https://fonts.googleapis.com/css2?family=Bangers&display=swap"); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Roboto", sans-serif;
  background-color: #6bcc48;
}
