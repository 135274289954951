.container {
  padding: 0 20px;
}

header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-card__content {
  position: relative;
  padding: 16px 23px;
  padding-top: 80px;
  background-color: #e1f5da;
  border: 2px solid #000;
  border-radius: 20px;
}

.home-card__title {
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  background-color: #54c92b;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px solid #000;
  border-radius: 20px;
  min-height: 66px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.home-card__title h3 {
  margin-bottom: 0;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.2;
}

.list-btn {
  margin-top: 35px;
}

.drawer-update .ant-drawer-content-wrapper {
  padding: 0 20px;
  padding-bottom: 20px;
  min-height: calc(100vh - 70px) !important;
}

.drawer-update .ant-drawer-header-title {
  flex-direction: row-reverse;
}

.drawer-update .ant-drawer-content {
  border-radius: 20px;
  background: #dfffd3;
}

.drawer-update .ant-drawer-header {
  background: #dfffd3;
  border: none;
  padding: 15px;
}

.drawer-update .ant-drawer-title {
  font-size: 18px;
  font-weight: 700;
}

.drawer-update .ant-drawer-body {
  padding: 15px;
}

.form-update .ant-input-number,
.form-update .ant-input-number-group-wrapper,
.form-update .ant-select,
.form-update .ant-picker,
.form-update .ant-input {
  width: 100%;
  border: none;
  border-radius: 10px;
}

.form-update .ant-select-selector {
  border: none !important;
  border-radius: 10px !important;
}

.form-update .ant-input-number-group .ant-input-number {
  border-right: none;
  border: none;
  border-radius: 10px 0 0 10px;
}

.form-update .ant-input-number-group .ant-input-number-group-addon,
.form-update .ant-input-group .ant-input-group-addon {
  background: #fff;
  border: none;
  border-radius: 0 10px 10px 0;
}

.form-update .ant-form-item {
  margin-bottom: 10px;
}

.form-update .ant-form-item-label {
  padding-bottom: 5px;
}

.form-update .ant-form-item-label label {
  color: #8aa0bc;
  font-size: 14px;
}

.form-update .ant-upload-list-item {
  background: #fff;
}

.form-update label.ant-form-item-required {
  flex-direction: row-reverse;
}

.btn-agree {
  background: #eff8ec;
  border: 1px solid #000000;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
}

.btn-primary {
  position: relative;
  background: #b5eca2;
  border: 1px solid #389e0d;
  border-radius: 10px;
  margin-bottom: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  height: initial;
}

.btn-primary span {
  font-weight: 700;
  font-size: 16px;
}

.btn-primary:hover,
.btn-primary:focus {
  border-color: #389e0d;
}

.btn-primary:hover span,
.btn-primary:focus span {
  color: #389e0d;
}

.btn-bg {
  background: #b5eca2;
  border: 1px solid #389e0d;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 700;
  color: #1890ff;
  min-width: 90px;
}

.btn-icon {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 55px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn-submit {
  background: #389e0d;
  border: 1px solid #389e0d;
  border-radius: 10px;
  padding: 8px 30px;
  height: initial;
}

.btn-submit span {
  font-weight: 500;
  font-size: 16px;
  color: #fff;
}

.btn-submit:hover,
.btn-submit:focus {
  border-color: #389e0d;
}

.btn-submit:hover span,
.btn-submit:focus span {
  color: #389e0d;
  font-weight: 700;
}

.btn-second {
  background: #fff;
  border: 1px solid #389e0d;
  border-radius: 10px;
  padding: 8px 30px;
  height: initial;
}

.btn-second span {
  font-weight: 500;
  font-size: 16px;
  color: #389e0d;
}

.btn-second:hover,
.btn-second:focus {
  border-color: #389e0d;
  background: #389e0d;
}

.btn-second:hover span,
.btn-second:focus span {
  color: #fff;
  font-weight: 700;
}

.btn-upload {
  /* margin-top: 25px; */
  background: transparent;
  border: 0.4px solid #000;
  border-radius: 5px;
}

.btn-upload span {
  font-size: 14px;
  font-weight: 600;
}

.btn-upload:hover,
.btn-upload:focus {
  border-color: #000;
}

.btn-upload:hover span,
.btn-upload:focus span {
  color: #000;
}

.btn-upload img {
  margin-right: 5px;
}

/* .ranks-tabs {
  margin-top: 20px;
} */

.ranks-tabs .ant-tabs-nav {
  margin-bottom: 0;
}

.ranks-tabs .ant-tabs-nav-list {
  background: #fff;
  width: 100%;
}

.ranks-tabs .ant-tabs-nav-list .ant-tabs-tab {
  margin: 0;
  flex: 1;
  justify-content: center;
  font-size: 14px;
  font-weight: 700;
  padding: 8px 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.ranks-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #389e0d;
}

.ranks-tabs .ant-tabs-ink-bar {
  bottom: initial !important;
  background: #389e0d;
}

.list-ranks {
  padding: 0 20px;
  flex: 1;
  overflow-y: auto;
}

.tabs-pagination {
  text-align: center;
}

.tabs-pagination li {
  margin: 0;
}

.tabs-pagination .ant-pagination-item-active {
  background: #4fb72a;
  border: 0.5px solid #dadada;
}

.tabs-pagination .ant-pagination-item-active a {
  color: #fff;
}

.tabs-pagination .ant-pagination-jump-next,
.tabs-pagination .ant-pagination-jump-prev {
  background: #fff;
}

p.event-end {
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  color: #fff;
  padding: 25px 0;
}

.tabs-modal .ant-modal-body {
  padding: 16px 24px;
}

.tabs-modal .ant-modal-body p {
  margin-top: 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
}

.tabs-modal .ant-modal-content {
  border-radius: 8px;
}

/* .drawer-history .ant-drawer-content-wrapper {
  min-height: 120px !important;
  height: initial !important;
} */

.drawer-history .ant-drawer-body {
  padding: 0;
  /* margin-top: 10px; */
  max-height: calc(100vh - 120px) !important;
  overflow-y: auto;
}

.history-collapse {
  border: 0;
  background: transparent;
}

.history-collapse .ant-collapse-item {
  border: none;
}

.history-collapse .ant-collapse-header {
  border-bottom: 2px solid #ddd;
  background: #fff;
  border-radius: 10px 10px 0px 0px !important;
  padding: 20px 40px 20px 24px !important;
  box-shadow: 0 -2px 15px 3px #ddd;
}

.history-collapse
  .ant-collapse-item:not(.history-collapse .ant-collapse-item:first-child) {
  margin-top: -10px;
}

.history-collapse .ant-collapse-header .anticon {
  color: #0b8235;
  font-size: 18px !important;
}

.history-collapse .ant-collapse-content {
  border: none;
  background: #dfffd3;
  max-height: 60vh;
  overflow-y: auto;
}

.history-collapse .ant-collapse-header .history-collapse__title {
  flex: 1;
  font-size: 16px;
  color: #0b8235;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* .drawer-gift .ant-drawer-content-wrapper,
.drawer-ranks .ant-drawer-content-wrapper {
  min-height: calc(100vh - 70px) !important;
} */

/* .drawer-gift .ant-drawer-header,
.drawer-ranks .ant-drawer-header {
  padding: 10px;
} */

.drawer-gift .ant-drawer-body,
.drawer-ranks .ant-drawer-body {
  padding: 0;
}

.gift,
.ranks {
  padding: 10px;
  padding-top: 0;
}

.gift p.white {
  font-size: 16px;
  font-weight: 700;
  color: #3aa015;
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

.gift p.green {
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #236238;
  /* transform: translateY(-80%); */
  margin-bottom: 30px;
}

.gift-content {
  position: relative;
  margin: 0 -10px;
}

.gift-svg {
  background: rgb(255, 255, 255);
  background: radial-gradient(
    50% 50% at 50% 50%,
    #ffffff 0%,
    rgba(255, 255, 255, 0.91) 5%,
    rgba(255, 255, 255, 0.67) 20%,
    rgba(255, 255, 255, 0.47) 35%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0.17) 64%,
    rgba(255, 255, 255, 0.08) 77%,
    rgba(255, 255, 255, 0.02) 90%,
    rgba(255, 255, 255, 0) 100%
  );
}

.gift-detail {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gift-content img {
  width: 100%;
  max-width: 275px;
}

p.no-data {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
  max-width: 310px;
  margin: 0 auto;
  margin-top: 20px;
}

@media screen and (min-width: 400px) {
  .space-list {
    max-width: 300px;
  }
}

.modal-accept .ant-modal-content {
  border-radius: 5px;
}

.modal-accept .ant-modal-body {
  padding: 20px;
}

.modal-accept .ant-modal-body .ant-modal-confirm-title {
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 15px;
}

.modal-accept .ant-modal-body .ant-modal-confirm-content {
  text-align: center;
}

.modal-accept .ant-progress-text {
  font-size: 17px;
  font-weight: 500;
}

.modal-accept .ant-btn {
  min-width: 70px;
  border-radius: 5px;
}

.modal-accept .ant-btn span {
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.5px;
  /* color: #fff; */
}

.modal-accept .ant-btn.ant-btn-primary {
  background-color: #3aa015;
  border-color: #3aa015;
}

.modal-accept .ant-btn.ant-btn-default {
  background-color: #dadada;
  border-color: #dadada;
}

.collapse-panel__primary {
  border: none !important;
}

.collapse-panel__primary > .ant-collapse-header {
  background: #6abf47;
  color: #fff !important;
  font-size: 16px;
  padding: 10px 25px !important;
  flex-direction: column;
  box-shadow: rgba(0, 0, 0, 0.15) 0px -50px 36px -28px inset;
}

.collapse-panel__primary.is-race > .ant-collapse-header {
  background: #8fdd73;
}

.collapse-panel__primary > .ant-collapse-header p {
  margin-bottom: 0;
}

.collapse-panel__primary > .ant-collapse-content > .ant-collapse-content-box {
  padding: 16px 0 0 0;
}

/* .collapse-panel__primary > .ant-collapse-content {
  max-height: 60vh;
  overflow-y: auto;
} */

.drawer-list-gift .ant-collapse-content-box {
  padding: 0 !important;
}

.drawer-list-gift .gift-item {
  padding: 15px 25px;
  font-size: 16px;
  color: #0b8235;
  border-bottom: 1px solid rgba(0, 0, 0, 0.27);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-gift .ant-modal-content {
  background: transparent;
  box-shadow: none;
}

.modal-gift .ant-modal-content button {
  display: none;
}

.modal-gift .ant-modal-body {
  padding: 0;
}

.ant-drawer-close {
  color: #389e0d;
}

.btn-down {
  border: none;
}

.btn-down:hover span,
.btn-down:focus span {
  color: #389e0d;
}

.ant-input-number-disabled .ant-input-number-input {
  color: #6e6e6e;
}

.bg-trans {
  background: transparent !important;
}

.drawer-history .ant-collapse-content {
  border-bottom: 2px solid #ddd;
}

.drawer-history.drawer-list-gift .ant-collapse-content {
  border-bottom: none;
}

.content-tabs-ranks {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.ranks,
.ranks-tabs,
.ranks-tabs .ant-tabs-content {
  height: 100%;
}

.btn-back {
  display: inline-block;
  padding: 10px;
  line-height: 1;
  margin-left: -10px;
}

.btn-back span {
  color: #fff;
  font-size: 28px;
}

/* .home-card__content .ant-typography p,
.home-card__content .ant-typography li {
  display: inline-block;
} */

.home-card__text p {
  display: inline;
}

.home-card__text p::after {
  content: " \A";
  white-space: pre;
}

.ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}
